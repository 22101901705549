<template>
<div class="CategorySection" style="background-color:rgb(245, 245, 245)">
  <div class="TextAndTextMargin">
    <SubHeading class="subHeading" style="margin-bottom: 16px" title="MORE INFORMATION" uniqueId="FAQSubHeading"/>
    <Heading class="heading" style="margin-bottom: 20px" title="FREQUENTLY ASKED QUESTIONS" uniqueId="FAQHeading" speed="20"/>
    <div class="textAndTextWrapper" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'futureObject')" :class="{ 'animate-text-block' : textVisible }">
      <div class="leftColumn">
        <vue-collapsible-panel-group border-color="#ff0000">
            <vue-collapsible-panel :expanded="false">
                <template #title>
                    How to mint?
                </template>
                <template #content>
                    <p>Minting is only available on OpenSea. Be aware of scams and only use links provided on this website!</p>
                </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
                <template #title>
                    What is the value?
                </template>
                <template #content>
                    <p>When you buy a FUTUREBOT, you show the world that you care about the future! You will get FUTUREBOTs of upcoming Collections for a strongly decreased price. Also, you are part of an exclusive community of thinkers that will achieve unique achievements in the NFT industry and want to make their contribution to the future.</p>
                </template>
            </vue-collapsible-panel>
        </vue-collapsible-panel-group>
      </div>
      <div class="rightColumn">
        <vue-collapsible-panel-group border-color="#ff0000">
            <vue-collapsible-panel :expanded="false">
                <template #title>
                    Where can I view my NFT?
                </template>
                <template #content>
                    <p>Once minted, you'll see your NFT in your OpenSea account.</p>
                </template>
            </vue-collapsible-panel>
            <vue-collapsible-panel :expanded="false">
                <template #title>
                    Can you really predict the future of 2050?
                </template>
                <template #content>
                    <p>Of course, we cannot predict the future in 2050. However, our FUTUREBOTs can. &#128521; The best way to predict the future is to actively create it. We should all do our part.</p>
                </template>
            </vue-collapsible-panel>
        </vue-collapsible-panel-group>
      </div>
    </div>
  </div>
</div>
</template>
 
<script>
 import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from './vue-collapsible-panel.common.js'
import './FAQ_CSS.css'
import SubHeading from '@/components/TextElements/SubHeading.vue'
import Heading from '@/components/TextElements/Heading.vue'
export default {
  name: 'FrequentlyAskedQuestions',
  components: {
    SubHeading,
    Heading,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel
  },
  data() {
        return {
          textVisible: false,
        }
  },
  methods: {
    visibilityChanged (isVisible, entry, element) {
      if (element == 'futureObject') {
        this.textVisible = isVisible;
      }

    },
  },
}
</script>

<style scoped>
.vcpg[data-v-63943e43][data-v-0574f580]{
    border: 0px;
}

.vcpg[data-v-63943e43][data-v-6784cc8e]{
  border: 0px solid red;
}

.vcp .vcp--expandable{
    margin: 7px;
}

p{
    margin-top: 10px;
    margin-bottom: 10px;
    color: gray;
}
.leftColumn{
    width: 50%;
    margin-right: 5px;
}

.rightColumn{
    flex: 1 1 50%;
    width: 100%;
    margin-left: 5px;
}

.heading{
  max-width: 600px;
  text-align: left;
}

@media screen and (max-width: 613px) {
  .heading{
    height: 96px;
  }
}
.subHeading{
    text-align: left;
}

@media screen and (max-width: 390px) {
  .heading{
    height: 144px;
  }
}
.subHeading{
    text-align: left;
}

.TextAndTextMargin{
    z-index: 1;
    width: 100%;
    max-width: 1500px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 30px;
    padding-left: 30px;
}
.textAndTextWrapper{
    display: flex;
}


@media screen and (max-width: 1311px) {
  .textAndTextWrapper{
      margin: 0px;
  }
}

@media screen and (max-width: 1096px) {
  .textAndTextWrapper{
      display: flex;
      /**margin: 0px +150px -15px;**/
      flex-flow: row-reverse wrap;
  }
  .leftColumn{
    width: 100%;
    position: relative;
    margin-left: 0px;
  }
  .heading{
    max-width: 100%;
  }
  .leftColumn{
      margin-right: 0px;
  }

  .rightColumn{
      margin-left: 0px;
  }
}

</style>